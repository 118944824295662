<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
/* import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import CKEditor from "@ckeditor/ckeditor5-vue"; */
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
import { format } from "date-fns";
import router from "@/router/index.js";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Attribuer badge",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "liste",
          active: true,
        },
      ],
      value: null,
      value1: null,
      options: ["Sexe", "Taille", "Vehicule", "je vois"],
      idUser: 0,
      dataUser: [],
      dataUserD: [],
      nbreUser: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "username", sortable: true, label: "Utilisateur" },
        /* { key: "tel", sortable: true, label: "Téléphone" },
        { key: "trajet", sortable: true, label: "Trajet effectué" },
        { key: "dateIns", sortable: true, label: "Inscription" },
        { key: "badge", label: "Détenteur badge" },
        { key: "statut", sortable: true, label: "Activer/Désactiver" }, */
        { key: "action", label: "Attribuer badge" },
      ],
      showDisable: false,
      showDelete: false,
      showEdit: false,
      showSucess: false,
      showEchec: false,
      userToUse: 0,
      infoUser:{
        firstname:"",
        lastname:"",
        boitepostale:"",
        profession:"",
        birthday:"",
        adresse:"",
        ville:"",
        pays:"",
        tel:"",
        email:"", 
      },
      showMessage: false,
      editor: ClassicEditor,
      editorData: "",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      //Chargement des données
    const data = await apiRequest("GET", "admin/users", undefined, false);
    if (data && data.data) {
      //console.log("data.data:", data.data);
      const formattedTable = data.data.map((user) => {
        return {
           id: user.id,
          username: user.lastname[0]==undefined ? "Nom Indéfini" : user.lastname[0] + ". " + user.firstname==undefined ? "Prénom Indéfini" : user.firstname,
          firstname:user.firstname,
          lastname:user.lastname,
          pays:user.pays,
          tel: user.tel,

          trajet: user.nombreTrajet,
          dateIns: format(
            new Date(user.dateCreation),
            "dd-MM-yyyy hh:mm:ss"
          ),
          badge: user.userValidated,
          statut: user.active ? "Actif" : "Suspendu",
        };
      });
      this.dataUser = formattedTable;
      console.log("data",data.data)
    }

    const dataD = await apiRequest("GET", "admin/users-inactif", undefined, false);
    if (dataD && dataD.data) {
      /*const formattedTableD = dataD.data.map((user) => {
          return {
           id: user.id,
          username: user.lastname[0]==undefined ? "Nom Indéfini" : user.lastname[0] + ". " + user.firstname==undefined ? "Prénom Indéfini" : user.firstname,
          firstname:user.firstname,
          lastname:user.lastname,
          tel: user.tel,
          trajet: user.nombreTrajet,
          dateIns: format(
            new Date(user.dateCreation),
            "dd-MM-yyyy hh:mm:ss"
          ),
          badge: user.userValidated,
          statut: user.active ? "Actif" : "Suspendu",
        };
      });*/

      const dataD = await apiRequest(
        "GET",
        "admin/users-inactif",
        undefined,
        false
      );
      if (dataD && dataD.data) {
        const formattedTableD = dataD.data.map((user) => {
          return {
            id: user.id,
            username:
              user.lastname[0] == undefined
                ? "Nom Indéfini"
                : user.lastname[0] + ". " + user.firstname == undefined
                ? "Prénom Indéfini"
                : user.firstname,
            tel: user.tel,
            trajet: user.nombreTrajet,
            dateIns: format(new Date(user.dateCreation), "dd-MM-yyyy hh:mm:ss"),
            badge: user.userValidated,
            statut: user.active ? "Actif" : "Suspendu",
          };
        });

        this.dataUserD = formattedTableD;
      }
      // Set the initial number of items
      this.totalRows = this.dataUser.length;
      this.nbreUser = this.dataUser.length;
    }},
    async addMessage() {
      this.showMessage = false;
      const data = await apiRequest(
        "POST",
        "admin/message-user",
        {
          idUser: this.messageContent.userId,
          typeMessage: this.messageSelect.value,
          subject: this.messageContent.sujet,
          text: this.messageContent.message,
        },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.init();
        console.log(data);
        this.showSucess = true;
      } else {
        this.showEchec = true;
      }
    },
    EditModal() {
      this.submitted = false;
      this.showEdit = false;
    },
    editClick(row){
      this.showEdit = true;
    console.log("LAST NAME: ",row)
     this.infoUser.lastname=row.lastname;
     this.infoUser.firstname=row.firstname
     console.log("LAST NAME: ",row.firstname)
    },
    desactiverUser(row){
      this.userToUse = row.id
      //console.log('userToDel:', this.userToUse)
      this.showDisable = true;
    },
    async desactiveUser() {
      this.showDisable = false;
      this.position();
      const data = await apiRequest(
        "POST",
        "admin/suspendre-user",
        { idUser: this.userToUse },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.init();
      }
    },
    messageShow(row) {
      this.showMessage = true;
      this.messageContent.userId = row.id;
      console.log(row.id);
    },
    async activeUser(row) {
      this.userToUse = row.id;
      this.position();
      const data = await apiRequest(
        "POST",
        "admin/activer-user",
        { idUser: this.userToUse },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.init();
      }
    },
    deleteUser(row) {
      this.userToUse = row.id;
      //console.log('userToDel:', this.userToUse)
      this.showDelete = true;
    },
    async deletUser() {
      this.showDelete = false;
      this.position();
      const data = await apiRequest(
        "POST",
        "admin/suspendre-user",
        { idUser: this.userToUse },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.init();
      }
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
      console.log("row:", row);
      router.push(`/user/user_info/${row.id}`);
      //router.push('/user/user_info/'+row.id)
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <h4 class="card-title mt-4">
              Nombre Total d'utilisateur : {{ rows }}
            </h4>
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Utilisateurs</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <!-- <label class="d-inline-flex align-items-center">
                        Choisir
                    <multiselect
                    v-model="value1"
                    :options="options"
                    :multiple="true"
                    :searchable="false" class="col-md-12"
                    ></multiselect>
                    </label> -->
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor:pointer;"
                    class="table-centered"
                    :items="dataUser"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(badge)="row">
                      <div
                        class="text-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Suspendu',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="desactiverUser(row.item)"
                        title="attribuer"
                      >
                        <i class="mdi mdi-check font-size-18"></i>
                      </a>
                      
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Comptes désactivés</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6"></div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor:pointer;"
                    class="table-centered"
                    :items="dataUserD"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(badge)="row">
                      <div
                        class="text-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Suspendu',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a
                        href="javascript:void(0);"
                        class=" text-primary"
                        v-b-tooltip.hover
                        @click="editClick(row.item)"
                        title="Editer"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="activeUser(row.item)"
                        title="Activer"
                      >
                        <i class="mdi mdi-check font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class=" text-danger"
                        v-b-tooltip.hover
                        @click="deleteUser(row.item)"
                        title="Supprimer"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDisable"
      title="Voulez-vous vraiment suspendre cet utilisateur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactiveUser"
          >Oui</b-button
        >
      </div>
    </b-modal>
  </Layout>
</template>
